import { FC } from 'react';

import { Icon, IconOption } from '@common/atoms/Icon';

import styles from './NavArrow.module.scss';

interface Props {
    onClick: () => void;
    className?: string;
    icon: IconOption;
}

export const NavArrow: FC<Props> = ({ onClick, className, icon }) => {
    const ChosenIcon = Icon[icon];
    return (
        <span className={`${styles.NavArrow} ${className}`} onClick={() => onClick()}>
            <ChosenIcon />
        </span>
    );
};
