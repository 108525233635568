import { FC } from 'react';
import useTranslation from 'next-translate/useTranslation';

import { News } from '@common/clients/api';
import { Divider } from '@web/molecules/NewsList/Divider';
import { FeaturedItem } from '@web/molecules/NewsList/FeaturedItem';
import styles from '@web/molecules/NewsList/NewsList.module.scss';

interface Props {
    items: News[];
}

export const FeaturedNewsList: FC<Props> = ({ items }) => {
    const __newsList = useTranslation('newslist').t;
    return items && items.length > 0 ? (
        <section className={`${styles.NewsList} ${styles['list-display-type']}`}>
            <Divider
                title={<h3>{__newsList('featured')}</h3>}
                className={styles.divider + ' ' + styles.title}
                hasBorder={false}
                isEmphasized
            />
            {items?.map((item) => <FeaturedItem item={item} key={`featuredItem-${item.newsID}`} />)}
        </section>
    ) : null;
};
