import { FC } from 'react';

import { ModuleScheduleSection, NotificationBarCategory } from '@common/clients/api';

import { Route } from '../InternalLink';

interface Props {
    bookmakerID: number;
    module: ModuleScheduleSection | NotificationBarCategory.BETTING;
    route?: Route;
}

export const BettingIntegrationTracker: FC<Props> = ({ bookmakerID, module, route }) => {
    const moduleTracker = `${module}_${bookmakerID}`;
    const routeKey = route ? Object.keys(Route)[Object.values(Route).indexOf(route)] : '';

    const moduleTrackerWithRoute = `${module}_${routeKey}_${bookmakerID}`;
    return (
        <span
            className="ndm-betting-integration-tracker"
            data-tracker-name={route ? moduleTrackerWithRoute : moduleTracker}
        />
    );
};
