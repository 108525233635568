import { FC } from 'react';
import useTranslation from 'next-translate/useTranslation';

import { Icon } from '@common/atoms/Icon';
import { ModuleScheduleItem, News, PlatformID, Tag } from '@common/clients/api';
import { useContextData } from '@common/useContextData';
import { DisplayType } from '@web/molecules/NewsList';

import styles from './TagElement.module.scss';

export interface Props {
    item: News | ModuleScheduleItem | any; //TODO: Figure the correct type here.
    allowSubtitle?: boolean;
    displayType?: DisplayType;
    tag?: Tag;
}

export const TagElement: FC<Props> = ({ item, allowSubtitle, displayType, tag }) => {
    const __components = useTranslation('components').t;

    let className = '';
    const contextData = useContextData();

    let content = item.hasOwnProperty('category') && item['category'].name;
    const betting = item.hasOwnProperty('bookmaker') && item.tag;

    // Determine which tag to use
    // TODO Make sure item.odd is available after implementing betting inserts - PB-3546
    // if (typeof item.odd !== null) {
    //     className = styles['betting'];
    //     content = __components('tag.breaking');
    // } else
    if (betting) {
        className = styles['betting'];
        content = item.tag;
    } else if (allowSubtitle && item.newsSubTitle) {
        className = 'larger';
        content = item.newsSubTitle;
    } else if (item.category?.slug === 'breaking-news') {
        content = __components('tag.breaking');
        className = styles['breaking'];
    } else if (item.category?.slug === 'done-deal') {
        className = styles['success'];
    } else if (item.category?.functionality === 'live') {
        className = styles['live'];
    } else if (item.category?.functionality === 'home-referee') {
        className = styles['rumor'];
    } else if (item.category?.functionality === 'advertorial') {
        className = styles['advertorial'];
    } else if (item.category?.functionality === 'betting') {
        className = styles['betting'];
    } else if (item.category?.slug === 'exclusive') {
        className = styles['exclusive'];
    } else if (item.category?.slug === 'exclusive-news' || item.category?.slug === 'exclusief-nieuws') {
        className = styles['exclusive-news'];
    } else if (item.category?.slug === 'rumors' || item.category?.slug === 'geruchten') {
        className = styles['rumor'];
    } else if (item.category?.slug === 'interview') {
        className = styles['interview'];
    } else if (item.category?.slug === 'column') {
        className = styles['column'];
    } else if (item.category?.functionality === 'podcast') {
        className = styles['podcast'];
    } else if (
        contextData.platform.id === PlatformID.BR &&
        item.category.slug !== 'nieuws' &&
        item.newsCatNameSlug !== 'algemeen' &&
        tag
    ) {
        className = styles['tag'];
    } else if (item.category?.isMedia === true || item.newsVideo) {
        className = styles['video'];
        content = __components('tag.video');
    }

    // No valid tag type, return empty
    if (className === '') return <></>;
    if (
        contextData.platform.id === PlatformID.BR &&
        item.category?.isMedia === true &&
        displayType === DisplayType.list
    )
        return (
            <div className={styles.VideoTag}>
                <span className={styles['icon-container'] + ' ' + className}>
                    <Icon.iconVideo />{' '}
                </span>
                <span className={styles.Tag + ' ' + className + ' tag'}>{content}</span>
            </div>
        );
    return <span className={styles.Tag + ' ' + className + ' tag'}>{content}</span>;
};
