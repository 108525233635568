import { FC } from 'react';

import { Link } from '@common/atoms/Link';
import { News } from '@common/clients/api';

import styles from './FeaturedItem.module.scss';

export interface Props {
    item: News;
}

export const FeaturedItem: FC<Props> = ({ item }) => {
    let path: string = '';
    if (item.host && item.path) {
        path = item.host + item.path;
    }
    return (
        <li className={styles.FeaturedItem}>
            <Link href={path}>
                <h4>{item?.newsTitle}</h4>
            </Link>
        </li>
    );
};
