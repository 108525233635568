import { FC } from 'react';

import { Icon } from '@common/atoms/Icon';
import { Link } from '@common/atoms/Link';

import styles from './ShowMoreLink.module.scss';

export const ShowMoreLink: FC<{ href?: string; showMoreText?: string }> = (props) =>
    props?.href ? (
        <Link href={props.href} className={styles.ShowMoreLink}>
            {props?.showMoreText || ''}
            <Icon.right />
        </Link>
    ) : null;
