import { FC, useMemo } from 'react';

import { Link } from '@common/atoms/Link/Link';
import { ModuleScheduleItem, ModuleScheduleSection, OddsDossierItem } from '@common/clients/api';
import { BettingDisclaimer } from '@web/atoms/BettingDisclaimer';
import { BettingIntegrationTracker } from '@web/atoms/BettingIntegrationTracker';
import { BookmakerLogo } from '@web/atoms/BookmakerLogo';
import { TagElement } from '@web/atoms/TagElement';

import { DisplayType } from '../NewsList';

import styles from './PromoItem.module.scss';

interface Props {
    item: ModuleScheduleItem | OddsDossierItem;
    displayType?: DisplayType;
    hasDarkBackground?: boolean;
    isDossier?: boolean;
}

export const PromoItem: FC<Props> = ({ item, displayType, hasDarkBackground, isDossier }) => {
    const size = useMemo(() => {
        return displayType === 'compact' ? 'small' : 'big';
    }, [displayType]);
    const url = item.url;
    const bookieUrl = item.bookmaker?.url;
    const text = (item as ModuleScheduleItem).text || (item as OddsDossierItem).title || '';
    const oddsDossierItem = (item as OddsDossierItem).tag || undefined;
    const promoItem = (item as ModuleScheduleItem).text || undefined;
    const icon = hasDarkBackground
        ? item?.bookmaker?.darkBackgroundIcon
        : item?.bookmaker?.lightBackgroundIcon;

    return (
        <li
            className={`${styles.PromoItem} ${styles[size]} promo-item ndm-betting-integration-tracker`}
            key={'promo-item_' + item.id}
            data-tracker-name={ModuleScheduleSection.TAG_NEWS_LIST}
        >
            {promoItem || oddsDossierItem ? (
                <div className={styles['betting-content']}>
                    {/* Use bookmaker url, else use item url */}
                    {promoItem || oddsDossierItem ? (
                        <div className={styles['tag-wrapper']}>
                            {oddsDossierItem ? (
                                <Link href={url ?? bookieUrl ?? oddsDossierItem ?? ''} isSponsored>
                                    <TagElement item={item ?? oddsDossierItem ?? undefined} />
                                </Link>
                            ) : null}
                        </div>
                    ) : null}
                    {/* Double classnames used to target styling in OddsDossierIntro.tsx */}
                    <div className={`${styles['content']} content`}>
                        <Link href={url ?? bookieUrl ?? ''} isSponsored>
                            <h4>{text}</h4>
                        </Link>
                    </div>
                    <div className={styles['logo-wrapper']}>
                        {promoItem ? (
                            <BookmakerLogo
                                className={styles['bookmaker-logo']}
                                alt={item?.bookmaker?.name || ''}
                                url={bookieUrl ?? url}
                                src={icon ?? ''}
                            />
                        ) : null}
                    </div>
                </div>
            ) : null}
            {promoItem ? (
                <BettingDisclaimer showEighteenPlusLogo />
            ) : (
                <div className={`${styles['logo-wrapper']} logo-wrapper`}>
                    <BookmakerLogo
                        className={styles['bookmaker-logo']}
                        alt={item?.bookmaker?.name || ''}
                        url={bookieUrl ?? url}
                        src={icon ?? ''}
                    />
                </div>
            )}
            {item.bookmaker?.id ? (
                <BettingIntegrationTracker
                    bookmakerID={item.bookmaker?.id}
                    module={
                        isDossier
                            ? ModuleScheduleSection.ODDS_DOSSIER_HOME
                            : ModuleScheduleSection.TAG_NEWS_LIST
                    }
                />
            ) : null}
        </li>
    );
};
