import { TagTypeAsString } from '../../../common/clients/api';
import { Route } from './Route';

export const RouteByTagType: Record<TagTypeAsString, Route> = {
    [TagTypeAsString.SOCCER_COMPETITION]: Route.Competition,
    [TagTypeAsString.SOCCER_CLUB]: Route.Club,
    [TagTypeAsString.SOCCER_PLAYER]: Route.Player,
    [TagTypeAsString.SOCCER_COMPETITION_TOURNAMENT]: Route.Competition,
    [TagTypeAsString.SOCCER_DOSSIER]: Route.Dossier,
    [TagTypeAsString.GPDRIVER]: Route.Driver,
    [TagTypeAsString.GPTEAM]: Route.Team,
    [TagTypeAsString.GPTEAM_BOSS]: Route.TeamBoss,
    [TagTypeAsString.GPGRAND_PRIX]: Route.Grandsprix,
    [TagTypeAsString.GPDOSSIER]: Route.Dossier,
    [TagTypeAsString.GPRACE_CLASS]: Route.RaceClass,
    [TagTypeAsString.GPCIRCUIT]: Route.Circuit,
    [TagTypeAsString.GPBRAND]: Route.Brand,
    [TagTypeAsString.GPCAR]: Route.Car,
    [TagTypeAsString.TOPICS]: Route.Topic,
    [TagTypeAsString.PEOPLE]: Route.Person,
    [TagTypeAsString.ORGANIZATIONS]: Route.Organization,
    [TagTypeAsString.LOCATIONS]: Route.Location,
};
